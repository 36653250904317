<template>
  <div class="table">
    <div class="header">
      <div class="duoxuan"></div>
      <div class="list">
        <div
          class="item"
          :style="{ width: item.width + '%' }"
          v-for="(item, index) in columns"
          :key="index"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="table_body">
      <div class="body" v-for="item in list" :key="item.treeId">
        <div class="duoxuan"></div>
        <div class="list">
          <div
            class="item"
            style="width: 10%"
            v-clipboard:copy="item.treeId"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ item.treeId }}
          </div>
          <div
            class="item"
            style="width: 5%"
            v-clipboard:copy="item.personnel"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ item.personnel }}
          </div>
          <div
            class="item"
            style="width: 11%"
            v-clipboard:copy="item.latitude"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ item.latitude }}
          </div>
          <div
            class="item"
            style="width: 11%"
            v-clipboard:copy="item.longitude"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ item.longitude }}
          </div>
          <div
            class="item"
            style="width: 8%"
            v-clipboard:copy="item.note"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ item.note }}
          </div>
          <div
            class="item"
            style="width: 10%"
            v-clipboard:copy="item.treeClass"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ item.treeClass }}
          </div>
          <div
            class="item"
            style="width: 10%"
            v-clipboard:copy="item.projectName"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ item.projectName }}
          </div>
          <div
            class="item"
            style="width: 10%"
            v-clipboard:copy="item.dieCause"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ item.dieCause }}
          </div>
		  <div class="item" style="width: 6%">
            {{ item.sample | sampleChange }}
          </div>
          <div
            class="item"
            style="width: 13%"
            v-clipboard:copy="item.createTime"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ item.createTime }}
          </div>
          <div
            class="item"
            style="width: 8%"
            v-clipboard:copy="item.groupName"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ item.groupName }}
          </div>
          <div class="item" style="width: 7%">
            <div
              class="yuan"
              :style="{
                'background-color':
                  item.status == 0
                    ? '#ff0000'
                    : item.status == '2'
                    ? '#09f'
                    : '#09f',
              }"
            ></div>
            {{
              item.status == "0"
                ? "未普查"
                : item.status == "2"
                ? "已普查"
                : "已普查"
            }}
          </div>
          <div class="item item1" style="width: 11%">
            <div class="yi" v-if="item.status == '0'">
              <div class="" style="color: #ff0000">未上传</div>
              <div class="" style="color: #999">暂无</div>
            </div>
            <div class="wei" v-else>
              <div class="" style="color: #09f" @click="handleDetail(item)">
                查看信息
              </div>
              <div
                v-if="groupIdList == 1 || modifyInv === '1'"
                class=""
                style="color: #09f"
                @click="amendClick(item)"
              >
                修改
              </div>
              <a-popconfirm
                v-if="groupIdList == 1"
                style="display: flex; align-items: center"
                title="确认删除"
                placement="bottom"
                ok-text="确认"
                cancel-text="取消"
                @confirm="deleteClick(item.treeId)"
              >
                删除
              </a-popconfirm>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 查看信息框 -->
    <el-dialog
      :title="title"
      :visible.sync="visible"
      width="30%"
      style="text-align: left"
    >
      <el-form ref="form" :model="examine" label-width="80px">
        <el-form-item label="疫目编号">
          <el-input style="width: 80%" v-model="examine.treeId"></el-input>
        </el-form-item>
        <el-form-item label="经度">
          <el-input style="width: 80%" v-model="examine.longitude"></el-input>
        </el-form-item>
        <el-form-item label="纬度">
          <el-input style="width: 80%" v-model="examine.latitude"></el-input>
        </el-form-item>
        <el-form-item label="地径(cm)">
          <el-input style="width: 80%" v-model="examine.note"></el-input>
        </el-form-item>
		<el-form-item label="海拔">
          <el-input style="width: 80%" v-model="examine.altitude"></el-input>
        </el-form-item>
        <el-form-item label="林业小班">
          <el-input style="width: 80%" v-model="examine.treeClass"></el-input>
        </el-form-item>
        <el-form-item label="所属项目">
          <el-select
            v-model="examine.projectId"
            class="t-job"
            style="width: 80%; margin-right: 40px"
          >
            <el-option
              :value="item.projectId"
              :label="item.projectName"
              v-for="(item, index) in projectList"
              :key="index"
            >
              {{ item.projectName }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="死亡原因">
          <el-select
            v-model="examine.dieCause"
            class="t-job"
            style="width: 80%; margin-right: 40px"
          >
            <el-option
              v-for="(item, index) in causeTypeArray"
              :key="index"
              :value="item"
              :label="item"
            >
              {{ item }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="作业人员">
          <el-select
            v-model="examine.groupId"
            class="t-job"
            style="width: 80%; margin-right: 40px"
          >
            <el-option
              :value="item.groupId"
              :label="item.groupName"
              v-for="item in groupList"
              :key="item.groupId"
            >
              {{ item.groupName }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="普查照片" v-if="images">
          <div style="width: 80%">
            <div v-show="examine.sysImageList == ''">暂未上传</div>
            <!-- <viewer v-show="examine.sysImageList !== ''"> -->
            <el-image
              v-show="examine.sysImageList !== ''"
              style="width: 6rem; height: 6rem; margin: 0 10px 10px 0"
              v-for="(item, index) in examine.sysImageList"
              :key="index"
              :src="api + '/' + item.imgPrefix + item.imgSrc"
              :preview-src-list="[api + '/' + item.imgPrefix + item.imgSrc]"
            ></el-image>
            <!-- <img style="width:6rem;height:6rem;margin: 0 10px 10px 0;" v-for="(item, index) in examine.sysImageList" :key="index" :src="api+ '/' + item.imgPrefix +item.imgSrc" alt=""> -->
            <!-- </viewer> -->
          </div>
        </el-form-item>
        <el-form-item label="普查照片" v-if="uimages">
          <div style="width: 80%">
            <viewer v-show="examine.sysImageList !== ''">
              <div class="img-vast">
                <div
                  class="img-border"
                  v-for="(item, index) in examine.sysImageList"
                  :key="index"
                >
                  <el-popconfirm
                    title="确定删除当前图片吗？"
                    @confirm="deleteClicks(item, examine.sysImageList)"
                  >
                    <div slot="reference" class="img-delete">x</div>
                  </el-popconfirm>
                  <img
                    style="width: 6rem; height: 6rem"
                    :src="api + '/' + item.imgPrefix + item.imgSrc"
                    alt=""
                  />
                </div>
              </div>
            </viewer>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" v-if="confirms" @click="affirmClicks"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { Message } from "element-ui";
export default {
  props: {
    list: Array,
  },
  data() {
    return {
      columns: [
        {
          title: "疫木编号",
          dataIndex: "treeId",
          key: "treeId",
          width: "10",
        },
        {
          title: "上传用户",
          dataIndex: "personnel",
          key: "personnel",
          width: "5",
        },
        {
          title: "纬度",
          dataIndex: "latitude",
          key: "latitude",
          width: "11",
        },
        {
          title: "经度",
          dataIndex: "longitude",
          key: "longitude",
          width: "11",
        },
        {
          title: "地径(cm)",
          dataIndex: "note",
          key: "note",
          width: "8",
        },
        {
          title: "林业小班",
          key: "treeClass",
          dataIndex: "treeClass",
          width: "10",
        },
        {
          title: "所属项目",
          key: "projectName",
          dataIndex: "projectName",
          width: "10",
        },
        {
          title: "死亡原因",
          key: "dieCause",
          dataIndex: "dieCause",
          width: "10",
        },
		{
          title: "是否取样",
          key: "sample",
          dataIndex: "sample",
          width: "6",
        },
        {
          title: "创建时间",
          key: "createTime",
          dataIndex: "createTime",
          width: "13",
        },
        {
          title: "作业人员",
          key: "groupName",
          dataIndex: "groupName",
          width: "8",
        },
        // {
        //   title: '小班号',
        //   key: '',
        //   dataIndex: '',
        //   width: '8',
        // },
        {
          title: "状态",
          key: "status",
          dataIndex: "status",
          width: "7",
        },
        {
          title: "操作",
          key: "action",
          dataIndex: "tags",
          width: "11",
        },
      ],
      locale: zhCN, // 弹框中文
      examine: {}, //信息框信息
      visible: false, //信息框显示/隐藏
      groupIdList: sessionStorage.getItem("groupId"),
      modifyInv: sessionStorage.getItem("modifyInv"),
      title: "查看详情",
      confirms: false,
      images: true, // 查看图片展示、隐藏
      uimages: true, // 修改图片展示、隐藏
      dialogVisible: true,
      treeOldId: "", //旧id
      groupList: [], //工作组、作业人员
      causeTypeArray: [
        "高度疑似松材线虫病",
        "松枝枯病后枯枝、死亡",
        "其他原因",
      ], //原因总数据
      projectList: [], //项目列表
    };
  },
  filters: {
    sampleChange(e) {
      return e == 1 ? "已取样" : "未取样";
    }
  },
  mounted() {
    this.onChange();
    this.onChanges();
  },
  methods: {
    // 复制成功
    onCopy() {
      this.$message.success("复制成功");
    },
    // 复制失败
    onError() {
      this.$message.info("复制失败");
    },
    // 获取工作组/作业人员
    async onChange() {
      const res = await this.$axios.get(this.api + "/group/list");
      if (res.code === 200) {
        this.groupList = res.data.list;
      } else {
        this.$message.info("作业人员获取失败，请联系管理人员");
      }
    },
    // 获取项目
    async onChanges() {
      const res = await this.$axios.get(this.api + "/project/list");
      if (res.code === 200) {
        this.projectList = res.data.list;
      } else {
        this.$message.info("项目获取失败，请联系管理人员");
      }
    },
    // 查看信息
    handleDetail(item) {
      this.title = "查看详情";
      this.confirms = false;
      this.examine = item;
      this.visible = true;
      this.images = true;
      this.uimages = false;
    },
    // 修改
    amendClick(item) {
      this.title = "修改";
      this.confirms = true;
      this.examine = item;
      this.visible = true;
      this.images = false;
      this.uimages = true;
      this.treeOldId = item.treeId;
    },
    // 删除图片
    async deleteClicks(item, e) {
      if (e.length < 3 || e.length == 3) {
        return Message.info("不可删除，至少保留三张");
      }
      const res = await this.$axios.delete(
        this.api + `/image/remove?srcList=${item.imgPrefix + item.imgSrc}`
      );
      if (res.code === 200) {
        Message.success("删除成功，请手动刷新");
        e.length = e.length - 1;
      } else {
        Message.info("删除失败，请重试");
      }
    },
    // 修改提交
    async affirmClicks() {
      if (this.title === "查看详情") {
        this.visible = false;
      } else {
        let data = {
          treeId: this.examine.treeId,
          treeOldId: this.treeOldId,
          longitude: this.examine.longitude,
          latitude: this.examine.latitude,
          treeClass: this.examine.treeClass,
          altitude: this.examine.altitude,
          dieCause: this.examine.dieCause,
          note: this.examine.note,
          groupId: this.examine.groupId,
          projectId: this.examine.projectId
        };
        if(this.examine.longitude !== '' && this.examine.latitude !== '') {
          const res1 = await this.$axios.get(
            this.api + `/elimination/treeClass?longitude=${this.examine.longitude}&latitude=${this.examine.latitude}&projectId=${this.examine.projectId}`,
          );
          if (res1.code === 200) {
            if(res1.data !== '') {
              data.treeClass = res1.data
              this.updateClick(data)
            } else {
              data.treeClass = this.examine.treeClass
              this.updateClick(data)
            }
          } else {
            data.treeClass = this.examine.treeClass
            this.updateClick(data)
          }
        } else {
           data.treeClass = this.examine.treeClass
           this.updateClick(data)
        }
      }
    },
    async updateClick(data) {
        const res = await this.$axios.put(
          this.api + "/investigation/updateTreeId",
          data
        );
        if (res.code === 200) {
          this.visible = false;
          this.$message.success("提交成功，请手动更新");
        } else {
          this.$message.info("更新失败，请重试");
        }
    },
    // 删除
    async deleteClick(e) {
      let treeId = e;
      const res = await this.$axios.delete(
        this.api + "/investigation/delete/" + treeId
      );
      if (res.code === 200) {
        this.$message.success("已删除，请手动更新");
      } else {
        this.$message.info("删除失败，请重试");
      }
    },
  },
};
</script>

<style scoped>
/* 信息框 */
.e_information >>> .ant-modal-body {
  font-size: 14px;
  color: #333333;
  font-family: "Arial Normal", "Arial";
  padding: 5px 20px;
}
.e_information_flex {
  display: flex;
  align-items: center;
  margin: 1.5rem 2rem;
}
.e_information >>> .e_information_left {
  width: 19%;
}
.table {
  width: 100%;
  height: 100%;
  /* background-color: red; */
}
.yuan {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: red;
  margin-right: 5px;
}
.item1 > div {
  display: flex;
}
.yi > div,
.wei > div {
  margin-right: 10px;
  width: 4em;
}
.wei > div:hover {
  cursor: pointer;
}
.duoxuan {
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.duoxuan > div {
  width: 10px;
  height: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
}
.header {
  display: flex;
  height: 50px;
  background-color: #1f325e;
  border-bottom: 1px solid #005ea1;
  /* align-items: center; */
}
.header .list {
  background-color: #1f325e;
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
}
.table_body {
  height: calc(100% - 50px);
  overflow-y: auto;
}
.table_body .body {
  display: flex;
  min-height: 50px;
  border-bottom: 1px solid #005ea1;
  /* align-items: center; */
}
.table_body .list {
  /* background-color:#1f325e ; */
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
}
.item {
  display: flex;
  align-items: center;
}
/* 修改图片展示 */
.img-vast {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.img-border {
  border: 1px solid #ccc;
  margin: 0 10px 10px 0;
}
.img-delete {
  height: 16px;
  line-height: 16px;
  text-align: right;
  padding-right: 4px;
  border-bottom: 1px solid #cccccc;
  cursor: pointer;
}
</style>
